<script setup>
import { ref, defineProps, defineEmits, onMounted, computed, watch } from 'vue';
import OfModal from 'of_vue_utils/src/utils/OfModal.vue';
import OfInput from 'of_vue_utils/src/utils/OfInput.vue';
import OfWidget from 'of_vue_utils/src/utils/OfWidget.vue';
import { event } from '../../config.js';
import OfModalMessage from 'of_vue_utils/src/utils/OfModalMessage.vue';
import OfTable from 'of_vue_utils/src/utils/OfTable.vue';
import OfFormatDateTime from 'of_vue_utils/src/utils/OfFormatDateTime.vue';
import OfDivider from 'of_vue_utils/src/utils/OfDivider.vue';
import OfButton from 'of_vue_utils/src/utils/OfButton.vue';
import OfMergeSearch from './OfMergeSearch';
import OfMenuActions from 'of_vue_utils/src/utils/OfMenuActions.vue';
import OfIcon from 'of_vue_utils/src/utils/OfIcon.vue';
import requests from '../../OfEventRequests';
import { phraseState, phrasePaymentMethodType, phraseSource, phraseChannel, getStateColor, enforceRule, requestedAnonymized } from '../../functions.js';

const props = defineProps({
    businessPayment: Object,
    claim: Object,
    queueMode: String,
    queueCount: Number,
});

const businessPayment = ref(props.businessPayment)

const claim = ref(props.claim)

const contactTypeOptions = ref([{ slug: 'INDIVIDUAL', name: 'Privat' }, { slug: 'BUSINESS', name: 'Erhverv' }])

const mergeMode = ref(false)

const actionsDisabled = ref(null)

const emit = defineEmits(['close', 'reload', 'nextInQueue', 'exitQueueMode', 'changeBusinessPayment']);

const mergeHistory = ref([]);

const highlightedPayment = ref(null);

const approveText = computed(() => {
    if (businessPayment.value.state == 'APPROVED' && !isValidated.value && showOnTV.value) {
        return 'Godkend visning';
    } else if (businessPayment.value.state == 'APPROVED' && !isValidated.value && !showOnTV.value) {
        return 'Undlad visning';
    } else if (businessPayment.value.state == 'PENDING' && businessPayment.value.showOnBusinessCrawler || businessPayment.value.showOnIndividualCrawler) {
        return 'Godkend faktura og visning';
    } else if (businessPayment.value.state == 'PENDING' && !businessPayment.value.showOnBusinessCrawler && !businessPayment.value.showOnIndividualCrawler) {
        return 'Godkend faktura og undlad visning';
    }
    return 'Godkend';
});
const showOnTV = computed(() => {
    return !((businessPayment.value.requestedAnonymizedFromBusinessCrawler || !businessPayment.value.showOnBusinessCrawler)
        && (businessPayment.value.requestedAnonymizedFromIndivdualCrawler || !businessPayment.value.showOnIndividualCrawler))
});
const rejectText = computed(() => {
    return 'Afvis';
});
const isValidated = computed(() => {
    return businessPayment.value.state == 'APPROVED' && businessPayment.value.updatedTs;
});
const invalidEmail = computed(() => {
    if (businessPayment.value.email) {
        const rx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return !rx.test(businessPayment.value.email)
    }
    return false;

})
const belowThreshold = computed(() => {
    return businessPayment.value.amount < Number(event.value.businessPaymentThreshold);
});
const displayNameAvailable = computed(() => {
    if (event.value.showOnlyPaymentsByBusinessesOnBusinessCrawler) {
        return businessPayment.value.contactType == 'BUSINESS' && !belowThreshold.value;
    }
    else {
        return !belowThreshold.value;
    }
});
const displayMessageAvailable = computed(() => {
    if (event.value.showOnlyPaymentsByBusinessesOnBusinessCrawler) {
        return businessPayment.value.contactType == 'INDIVIDUAL';
    }
    else {
        return false;
    }
});
async function update(extraAction) {

    const form = document.getElementById('update-business-payment');

    if (!form.checkValidity()) {

        var tmpSubmit = document.createElement('button')
        form.appendChild(tmpSubmit)
        tmpSubmit.click()
        form.removeChild(tmpSubmit)

    } else if (invalidEmail.value) {
        alert('Ugyldig email')
    } else {

        const updatedBusinesPayment = { ...businessPayment.value };
        if (extraAction == 'approve') {
            updatedBusinesPayment.state = 'APPROVED';
        } else if (extraAction == 'reject') {
            updatedBusinesPayment.state = 'REJECTED';
        }

        if (!updatedBusinesPayment.displayName) {
            updatedBusinesPayment.displayName = 'Ikke oplyst';
        }
        if (belowThreshold.value) {
            updatedBusinesPayment.showOnBusinessCrawler = false;
        }
        await requests.put.businessPayment(updatedBusinesPayment.businessPaymentGuid, claim.value, updatedBusinesPayment).then(result => {
            businessPayment.value = result;

            if (extraAction) {
                emit('close');
                if (props.queueMode) {
                    emit('nextInQueue')
                } else {
                    emit('reload')
                }
            }

        })
    }
}
function merge(mergeSource, sourceClaim) {
    requests.post.mergeBusinessPayment(
        mergeSource.businessPaymentGuid,
        businessPayment.value.businessPaymentGuid,
        sourceClaim
    ).then((result) => {
        if (result) {

            requests.post.releaseBusinessPayment(mergeSource.businessPaymentGuid, sourceClaim)

            reloadBusinessPayment();

            getMergeHistory()

            emit('reload');
        }
    })
}
async function reloadBusinessPayment() {
    businessPayment.value = await requests.get.businessPayment(businessPayment.value.businessPaymentGuid);
}
function unmerge() {
    if (confirm('Unmerge this business payment?')) {
        requests.post.unmergeBusinessPayment(businessPayment.value.businessPaymentGuid, claim.value).then((result) => {
            businessPayment.value = result;
            getMergeHistory();
            emit('reload');
        })
    }
}
function resendReceipt() {
    if (confirm('Gensend kvitteringsemail?')) {
        update().then(() => {
            requests.post.resendReceiptBusinessPayment(businessPayment.value.businessPaymentGuid, claim.value);
        });
    }
}
async function getMergeHistory() {
    mergeHistory.value = await requests.get.mergeHistory(businessPayment.value.businessPaymentGuid)
}
function changeBusinessPayment(payment) {
    emit('close', true)
    if (props.queueMode) {
        emit('exit-queue-mode')
    }
    emit('changeBusinessPayment', payment);
}
watch(() => businessPayment.value.amount, () => {
    if (belowThreshold.value) {
        businessPayment.value.showOnBusinessCrawler = false;
    }
})
onMounted(() => {
    if (props.queueMode) {
        actionsDisabled.value = 5;

        const timer = setInterval(() => {
            actionsDisabled.value--;

            if (actionsDisabled.value == 0) {
                actionsDisabled.value == null;
                clearInterval(timer);
            }
        }, 1000);
    }
    if (!props.claim) {
        claim.value = { claimId: localStorage.getItem('claim') }
    }
    if (belowThreshold.value) {
        businessPayment.value.showOnBusinessCrawler = false;
    }
    else if (!businessPayment.value.updatedTs && !businessPayment.value.requestedAnonymizedFromBusinessCrawler) {
        // Default to checked
        businessPayment.value.showOnBusinessCrawler = true;
    }
    if (!businessPayment.value.updatedTs && !businessPayment.value.requestedAnonymizedFromIndividualCrawler && businessPayment.value.displayMessage) {
        businessPayment.value.showOnIndividualCrawler = true;
    }

    if (!businessPayment.value.showOnIndividualCrawler) {
        businessPayment.value.showOnIndividualCrawler = false;
    }

    if (!businessPayment.value.showOnBusinessCrawler) {
        businessPayment.value.showOnBusinessCrawler = false;
    }

    getMergeHistory()
})

</script>

<template>
    <form id="update-business-payment" @submit.prevent>
        <OfModal @close="(queueMode ? emit('exitQueueMode') : emit('close'))">

            <template #heading>
                <span :style="{ color: getStateColor(businessPayment) }">
                    Donation - {{ phraseState(businessPayment) }}
                </span>
            </template>

            <template #top>

                <OfMenuActions>
                    <li v-if="businessPayment.state != 'MERGED'">
                        <button type="button" @click="mergeMode = !mergeMode">Flet med anden donation</button>
                    </li>
                    <li v-if="businessPayment.state == 'MERGED'">
                        <button type="button" @click="unmerge">Fortryd fletning med anden donation</button>
                    </li>
                    <li v-if="businessPayment.state == 'MERGED'">
                        <a :href="`/?page=businesspayments&businessPayment=${businessPayment.mergeTargetGuid}`"><button
                                type="button">Gå til flettet donation</button></a>
                    </li>
                    <li v-if="businessPayment.state == 'APPROVED' && businessPayment.source != 'MANUAL'">
                        <button type="button" @click="resendReceipt">Gensend kvittering pr. email</button>
                    </li>
                </OfMenuActions>

                <div v-if="queueMode" class="queue-count">{{ queueCount }} i køen</div>

            </template>

            <template #left>
                <OfWidget class="widget-left">
                    <template #content>
                        <OfInput v-model="businessPayment.contactType" type="select" label="Kontakttype"
                            placeholder="Kontakttype" :options="contactTypeOptions" required />

                        <OfInput v-if="businessPayment.contactType == 'BUSINESS'" v-model="businessPayment.businessCode"
                            type="tel" label="CVR-nr." placeholder="CVR-nr." @keydown="enforceRule('digits', $event)" />

                        <a v-if="businessPayment.contactType == 'BUSINESS' && businessPayment.businessCode"
                            :href="'https://datacvr.virk.dk/enhed/virksomhed/' + businessPayment.businessCode"
                            target="_blank" class="property-link">

                            Slå op i CVR-registeret
                            <OfIcon type="search" />
                        </a>

                        <OfInput v-if="businessPayment.contactType == 'BUSINESS'" v-model="businessPayment.companyName"
                            type="text" label="Firmanavn" placeholder="Firmanavn" />

                        <a v-if="businessPayment.contactType == 'BUSINESS' && businessPayment.companyName"
                            :href="`https://www.google.com/search?&q=%22${businessPayment.companyName}%22`" target="_blank"
                            class="property-link">

                            Søg på Google
                            <OfIcon type="search" />
                        </a>

                        <OfInput v-model="businessPayment.msisdn" type="tel" label="Tlf.nr." placeholder="Tlf.nr."
                            @keydown="enforceRule('digits', $event)" :minlength="6" :maxlength="14" />

                        <OfInput v-model="businessPayment.email" type="email" label="Email" placeholder="Email" />

                        <p v-if="invalidEmail" class="property-warning">Ugyldig email</p>

                        <OfInput v-model="businessPayment.address" type="text" label="Adresse" placeholder="Adresse" />

                        <OfInput v-model="businessPayment.postCode" type="tel" label="Postnr." placeholder="Postnr."
                            @keydown="enforceRule('digits', $event)" />

                        <OfInput v-model="businessPayment.city" type="tel" label="By" placeholder="By" />

                    </template>

                </OfWidget>

            </template>
            <template #right>
                <OfWidget v-if="mergeHistory.length > 1">
                    <template #header>
                        <h3>Flettehistorik</h3>
                    </template>

                    <template #content>
                        <div class="merge-history">
                            <OfTable type="entity">
                                <template #thead>
                                    <th>Flettet</th>
                                    <th>Visningsnavn</th>
                                    <th>Beløb</th>
                                    <th>Betalingsmetode</th>
                                </template>

                                <template #tbody>
                                    <tr v-for="payment in mergeHistory" :key="payment.businessPaymentGuid"
                                        :class="{ highlighted: payment.businessPaymentGuid == highlightedPayment, bold: payment.businessPaymentGuid == businessPayment.businessPaymentGuid }"
                                        :title="(payment.businessPaymentGuid == businessPayment.businessPaymentGuid ? 'Denne donation' : '')"
                                        @click="changeBusinessPayment(payment)"
                                        @mouseenter="highlightedPayment = payment.mergeTargetGuid"
                                        @mouseleave="highlightedPayment = null">
                                        <td>
                                            <p style="min-height: 45px;">
                                                <template v-if="payment.mergeTs">
                                                    <OfFormatDateTime class="no-title" :date-time="payment.mergeTs" />
                                                </template>
                                            </p>
                                        </td>
                                        <td>
                                            <span> {{ payment.displayName }}</span>
                                        </td>
                                        <td>
                                            <span>
                                                {{ payment.amount.toLocaleString('da-DK',
                                                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
                                            </span>
                                        </td>
                                        <td>
                                            <span> {{ phrasePaymentMethodType(payment.paymentMethodType) }}</span>
                                        </td>

                                    </tr>
                                </template>
                            </OfTable>
                        </div>

                    </template>
                </OfWidget>

                <OfWidget class="widget-right">
                    <template #content>
                        <OfTable type="widgetinfo">
                            <template #tbody>

                                <tr>
                                    <td>Oprettet</td>
                                    <td>
                                        <OfFormatDateTime :date-time="businessPayment.createdTs" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>Kilde</td>
                                    <td>
                                        {{ phraseSource(businessPayment.source) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Kanal</td>
                                    <td>
                                        {{ phraseChannel(businessPayment.channel) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Betalingsmetode</td>
                                    <td>
                                        {{ phrasePaymentMethodType(businessPayment.paymentMethodType) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Originalt beløb</td>
                                    <td>
                                        {{ businessPayment.amountOriginal.toLocaleString('da-DK',
                                            { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
                                    </td>
                                </tr>

                            </template>
                        </OfTable>

                        <OfDivider direction="horizontal" />

                        <div class="inputs">
                            <p v-if="requestedAnonymized(businessPayment)" class="requested-anonymized">
                                Ønskes ikke vist på TV
                            </p>

                            <OfInput v-else-if="displayNameAvailable" v-model="businessPayment.showOnBusinessCrawler"
                                name="showOnBusinessCrawler" type="checkbox" label="Vis på TV" />

                            <OfInput v-else-if="displayMessageAvailable" v-model="businessPayment.showOnIndividualCrawler"
                                name="showOnIndividualCrawler" type="checkbox" label="Vis seeropfordring på TV" />

                            <OfInput v-model="businessPayment.amount" type="tel" label="Beløb" placeholder="Beløb"
                                :maxlength="8" required @keydown="enforceRule('number', $event)" />

                            <OfInput v-if="displayNameAvailable && businessPayment.showOnBusinessCrawler"
                                v-model="businessPayment.displayName" type="text" label="Visningsnavn"
                                placeholder="Visningsnavn" :maxlength="event.maxCrawlerDisplayNameLength" required />

                            <OfInput v-if="displayMessageAvailable && businessPayment.showOnIndividualCrawler" v-model="businessPayment.displayMessage"
                                type="text" label="Seeropfordring" placeholder="Seeropfordring"
                                :maxlength="event.maxCrawlerDisplayMessageLength" required />

                            <a v-if="businessPayment.showOnBusinessCrawler && businessPayment.displayName"
                                :href="`https://www.google.com/search?&q=%22${businessPayment.displayName}%22`"
                                target="_blank" class="property-link">
                                Søg på Google
                                <OfIcon type="search" />
                            </a>

                            <OfDivider direction="horizontal" />

                            <OfInput v-model="businessPayment.comment" type="textarea" label="Intern note" />
                        </div>

                    </template>
                </OfWidget>
                <div class="buttons" v-if="businessPayment.state != 'MERGED'">

                    <OfButton :text="approveText + (actionsDisabled > 0 ? ` (${actionsDisabled})` : '')" coloring="primary"
                        @click="update('approve')" :disabled="actionsDisabled" />

                    <OfButton v-if="!(businessPayment.state == 'APPROVED' && !isValidated)"
                        :text="'Opdatér oplysninger' + (actionsDisabled > 0 ? ` (${actionsDisabled})` : '')"
                        coloring="secondary" @click="update()" :disabled="actionsDisabled" />

                    <OfButton :text="rejectText + (actionsDisabled > 0 ? ` (${actionsDisabled})` : '')" coloring="cancel"
                        @click="update('reject')" :disabled="actionsDisabled || businessPayment.state == 'APPROVED'" />

                </div>
            </template>

            <template v-if="mergeMode" #middle>
                <OfWidget class="widget-right">
                    <template #content>
                        <div class="merge-sources">
                            <OfMergeSearch :target="businessPayment" @merge="merge" @close="mergeMode = false" />
                        </div>
                    </template>
                </OfWidget>
            </template>
            <template v-else #middle>
                <div class="state-banner">
                    <OfModalMessage :color="getStateColor(businessPayment, 'name')">
                        {{ phraseState(businessPayment) }}
                    </OfModalMessage>
                </div>

            </template>

        </OfModal>
    </form>
</template>
 
<style scoped>
.queue-count {
    flex-grow: 1;
    text-align: right;
    color: #6B6C6F;
    font-weight: 500;
}

.requested-anonymized {
    margin-bottom: 1rem;
    font-size: 14px;
}

.widget-left {
    padding: 1rem;
}

.widget-right :deep(.of-widget-table-info tr > td:first-child) {
    width: 36%;
}

.widget-right :deep(.divider) {
    margin: 0;
}

.widget-right .inputs {
    padding: 1rem;
    padding-left: 30px;
}

.widget-right .inputs>:deep(.row:last-of-type) {
    margin-top: 1rem;
}

.property-link,
.property-warning {
    margin-top: -5px;
    display: block;
    width: 100%;
    position: relative;
    text-align: right;
    margin-bottom: 1rem;
    color: var(--of-link-color);
    text-decoration: none;
}

p.property-warning {
    color: #E6492D;
}

.property-link :deep(i) {
    width: 15px;
    height: 15px;
    background-size: 15px;
    margin-left: 4px;
    margin-top: 2px;
}

.buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.state-banner :deep(i) {
    display: none;
}

.merge-history :deep(tr.highlighted td:first-child>p:before) {
    content: "\2192";
    font-size: 20px;
    left: 15px;
    position: absolute;
}

.merge-history :deep(tr.bold) {
    font-weight: 500;
}

.no-title {
    pointer-events: none;
}
</style>