<script setup>
import { onMounted, ref, defineEmits } from 'vue';
import { toaster } from '../../tools/toaster'
import { apiUrl, eventId, user, validUntilTs } from '../../config'
import axios from 'axios';

const action = ref('Login')
const email = ref(null)
const password = ref(null)
const password2 = ref(null)
const token = ref(null)
const capsOn = ref(false);

const emit = defineEmits(['loggedIn'])

function setActionForgotPassword() {
    action.value = 'ForgotPassword';
}
function setActionLogin() {
    action.value = 'Login';
}
function setActionChangePassword() {

    const params = new URLSearchParams(window.location.search);

    if (params.get('action') == 'ChangePassword' && params.get('token')) {
        action.value = "ChangePassword";
        token.value = params.get('token');
    }
}
function sendForgotPassword() {
    if (!email.value) {
        toaster.error("Missing email");
        return;
    }

    axios.post(apiUrl + '/user/ResetPassword?email=' + encodeURIComponent(email.value)).then(() => {
        toaster.success('Email sent with reset link. Please check your inbox')
    }).catch((error) => {
        toaster.error('Reset error. Please verify your email-address');
        console.log(error);
    })
}
function sendChangePassword() {
    if (!password.value || !password2.value) {
        toaster.error("Missing password");
        return;
    } else if (password.value != password2.value) {
        toaster.error("Passwords are not the same");
        return;
    }

    let body = JSON.stringify({
        password: password.value,
    });

    axios
        .post(
            apiUrl + '/user/ChangePassword?token=' + token.value,
            body,
            {
                headers: {
                    "Content-Type": 'application/json'
                },
            }
        ).then(() => {
            toaster.success('Password changed');

            password2.value = null;

            const params = new URLSearchParams(window.location.search);
            params.delete('action');
            params.delete('token');


            setActionLogin();

        }).catch((error) => {
            toaster.error('Your change was not accepted. Please send a new password reset request.');
            console.log(error);
        })
}
function sendLogin() {
    if (!email.value) {
        toaster.error("Missing email");
        return;
    } else if (!password.value) {
        toaster.error("Missing password");
        return;
    }

    //  Body
    let body = JSON.stringify({
        email: email.value,
        password: password.value,
    });

    axios
        .post(
            apiUrl + '/user/Login',
            body,
            {
                headers: {
                    "Content-Type": 'application/json'
                },
            }
        ).then((response) => {
            console.log(response.data)
            user.value.authToken = response.data.authToken
            eventId.value = response.data.eventId
            validUntilTs.value = response.data.eventId

            localStorage.setItem('authToken', response.data.authToken)
            localStorage.setItem('eventId', response.data.eventId)

            if (response.data.validUntilTs) {
                const authExp = Math.round(new Date(response.data.validUntilTs).getTime() / 1000);
                localStorage.setItem('authExp', authExp);

            }

            emit('loggedIn')

        }).catch((error) => {
            toaster.error('Invalid login. Please review');
            console.log(error);
        })
}
function checkCaps(event) {
    if (event && event.getModifierState && event.getModifierState("CapsLock")) {
        capsOn.value = true;
    } else {
        capsOn.value = false;
    }
}
onMounted(() => {
    setActionChangePassword();
})
</script>
<template>
    <div class="login">
        <div class="login-inner">

            <div v-if="action === 'ChangePassword'" class="block-header">
                <h1 class="title">Skift adgangskode</h1>
                <p class="desc">Indsæt din nye adgangskode to gange</p>
            </div>

            <div v-else-if="action === 'ForgotPassword'" class="block-header">
                <h1 class="title">Glemt adgangskode</h1>
                <p class="desc">
                    Indsæt din email-adresse for at nulstille din adgangskode via et link
                </p>
            </div>

            <div v-else class="block-header">
                <h1 class="title">Log-ind</h1>
                <p class="desc">Indsæt email-adresse og adgangskode</p>
            </div>

            <!-- Form -->
            <form method="post" action="" autocomplete="off" novalidate="novalidate" @submit.prevent
                @keyup.enter="sendLogin()" @keyup="checkCaps">

                <!-- Email -->
                <div v-if="action === 'Login' || action === 'ForgotPassword'" class="control-input">
                    <input type="email" id="email" class="uik-input__input" placeholder="Email" v-model="email"
                        autocomplete="email" required />
                </div>

                <!-- Password -->
                <div v-if="action === 'Login' || action === 'ChangePassword'" class="control-input">
                    <input type="password" id="password" class="uik-input__input" placeholder="Adgangskode"
                        v-model="password" autocomplete="off" required />
                </div>

                <!-- Password 2 -->
                <div v-if="action === 'ChangePassword'" class="control-input">
                    <input type="password" id="password2" class="uik-input__input" placeholder="Adgangskode igen"
                        v-model="password2" autocomplete="off" required />
                </div>

                <p v-if="capsOn">
                    Caps lock er slået til
                </p>

                <!-- Buttons -->
                <div class="form-footer">
                    <button v-if="action === 'ChangePassword'" type="button" v-on:click="sendChangePassword"
                        class="uik-btn__base btn btn-primary">
                        Skift adgangskode
                    </button>
                    <button v-else-if="action === 'ForgotPassword'" type="button" v-on:click="sendForgotPassword"
                        class="uik-btn__base btn btn-primary">
                        Nulstil adgangskode
                    </button>
                    <button v-else-if="action === 'Login'" type="button" v-on:click="sendLogin"
                        class="uik-btn__base btn btn-primary">
                        Log-ind
                    </button>
                </div>
            </form>

            <!-- Footer -->
            <button v-if="action === 'ForgotPassword'" type="button" v-on:click="setActionLogin"
                class="go-to-login-btn btn">
                Gå til log-ind
            </button>
            <button v-else-if="action === 'Login'" type="button" v-on:click="setActionForgotPassword"
                class="forgot-btn btn">
                Glemt adgangskode?
            </button>

        </div>
    </div>
</template>

<style scoped>
.login {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(-16deg, #0f5bc2, #3ab1e6);
}

.login-inner {
    margin: 0 auto;
    max-width: 450px;
    width: 100%;
    background: #fff;
    padding: 0 30px 30px;
    border-radius: 4px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, .15);
    border: 1px solid #eaedf3;
    text-align: center;
}

.login .block-header {
    text-align: center;
    padding: 40px 30px 25px;
    margin-bottom: 15px;
}

.login .control-input {
    margin-bottom: 15px;
}

.login input {
    color: #444;
    border: 1px solid #bbb;
    border-radius: 4px;
    display: block;
    width: 100%;
    background: #fff;
    transition: all .2s;
    outline: none;
    font-size: .875rem;
    line-height: 1.3em;
    -webkit-appearance: none;

    height: 48px;
    padding: 5px 10px;

}

.login .form-footer {
    border-top: 1px solid #eaedf3;
    margin-top: 30px;
    padding: 30px 0 0;
}

.login .form-footer button {
    display: inline-flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 0 18px;
    transition: all .1s ease-in-out 0s;
    font-size: .875rem;
    font-weight: 500;
    position: relative;
    border: 1px solid #d8dce6;
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, .05), inset 0 2px 0 0 hsla(0, 0%, 100%, .05);
    border-radius: 4px;

    width: 100%;
    justify-content: center;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    background: linear-gradient(45deg, rgba(15, 91, 194, .9), rgba(58, 177, 230, .9));
    border-color: rgba(15, 91, 194, .5);
    color: #fff;
}

.login .btn {
    height: 48px;
}

.forgot-btn,
.go-to-login-btn {
    border: 0;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    padding: 0;
    min-height: auto;
    background: transparent;
    color: rgba(15, 91, 194, .8);
    font-weight: 500;
}
</style>