<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import axios from 'axios';

import OfLogin from './components/account/OfLogin.vue';
import OfNavBarLeft from './components/navigation/OfNavBarLeft.vue';
import OfNavBarTop from './components/navigation/OfNavBarTop.vue';
import OfEventPageHandler from './components/pages/OfEventPageHandler.vue';
import requests from './OfEventRequests';
import { apiUrl, event, user } from './config.js';
import { toaster } from './tools/toaster';
import { currentPage } from './tools/pageHandler';

const loginInvalid = ref(null);

async function getEvent() {

  //  Request event
  if (user.value.authToken) {
    try {
      const response = await requests.get.event()

      event.value = response;

    } catch (error) {
      //  Error
      console.log(error);
    }
  }
}
async function getUser() {
  checkLogin();
  if (user.value.authToken) {
    console.log("account verifying");

    //  Request
    try {
      const response = await axios
        .get(apiUrl + "/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.value.authToken
          }
        });
      handleAccountResponse(response.data);
    } catch (error) {
      handleAccountError(error);
    }
  }
}
function checkLogin() {
  const nowInSeconds = Math.round(new Date().getTime() / 1000);

  const authExp = localStorage.getItem("authExp");

  if (authExp && nowInSeconds > authExp) {

    loginInvalid.value = true;
    //  Force logout
    logOut();
    console.log("login expired");

    toaster.error('Login has expired. Please login again')
  }
  //  Transfer token
  else if (!user.value.authToken) {

    user.value.authToken = localStorage.getItem("authToken");
  }
}
function logOut() {

  user.value = {
    authToken: null,
    userGuid: null,
    name: null
  };

  //  Empty authToken and authExp in browser session
  localStorage.removeItem("authToken");
  localStorage.removeItem('eventId');
  localStorage.removeItem("authExp");

  currentPage.value = '';

  loginInvalid.value = false;
}
/**
 * Handle response from account
 * @param {object} userData
 */
function handleAccountResponse(userData) {

  //  Extract details
  user.value.name = userData.name;
  user.value.userGuid = userData.userGuid;

  console.log("account verified");
}

/**
 * Handle error from account
 * @param {object} error 
 */
function handleAccountError(error) {

  logOut();

  console.log("login not verified");

  console.log(error)
  toaster.error("Unverified access. Please login");
}
onBeforeMount(() => {

})
onMounted(() => {
  getUser();
  getEvent();
})
</script>

<template>
  <div v-if="loginInvalid || !user.authToken">
    <OfLogin @logged-in="getEvent" />
  </div>

  <div v-else-if="currentPage == 'logout'">
    <a style="cursor: pointer;" @click.prevent="logOut">Log out</a>
  </div>

  <div v-else-if="event" class="event">
    <div class="nav-left">
      <OfNavBarLeft @logout="logOut" />
    </div>
    <div class="nav-top">
      <OfNavBarTop />
    </div>
    <div class="content">
      <OfEventPageHandler />
    </div>
  </div>
</template>

<style scoped>
.event {
  display: grid;
  grid-template-columns: 180px auto;
  grid-template-rows: 72px auto;
  grid-template-areas:
    "left top"
    "left content"
}

.nav-left {
  background: #212121;
  grid-area: left;
}

.nav-top {
  position: sticky;
  top: 0;
  grid-area: top;
  z-index: 900;
}

.content {
  grid-area: content;
  padding: 30px;
}

.content :deep(.of-modal) {
  height: auto;
  overflow-y: auto;
  max-height: 90vh;
  padding: 0 0 30px;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content :deep(.of-modal .go-back) {
  display: none;
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafc;
}
</style>