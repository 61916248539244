<script setup>
import { ref, defineEmits } from 'vue';
import { currentPage } from '../../tools/pageHandler';
import { event, callcenterUrl, configProduction } from '../../config.js';

const emit = defineEmits(['logout'])

const primaryMenu = ref([
    { label: 'Oversigt', page: '' },
    { label: 'Tv-Crawler', page: 'businesspayments' },
    { label: 'Korrektioner', page: 'corrections' },
    //{ label: 'SMS', page: 'campaigns' }

])

function eventState() {

    if (new Date() > new Date(event.aggregationStartTs) && new Date() < new Date(event.aggregationEndTs)) {
        return 'ON AIR';
    } else if (configProduction) {
        return 'LIVE';
    }

    return 'TEST';
}
</script>

<template>
    <div class="brand" v-bind:class="(configProduction ? 'live' : 'test')">
        {{ eventState() }}
    </div>
    <div class="nav-wrap">
        <nav class="primary-menu">
            <ul>
                <li v-for="item in primaryMenu" :key="item.page" :class="{ active: currentPage == item.page }"
                    @click="currentPage = item.page">
                    {{ item.label }}
                </li>
            </ul>
        </nav>
        <nav class="support-menu">
            <ul v-if="false">
                <li>
                    <div>
                        <a v-bind:href="callcenterUrl" target="_blank">
                            Åbn callcenter
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="logout">
                <li>
                    <div>
                        <button type="button" @click="emit('logout')">
                            Log ud
                        </button>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</template>

<style scoped>
.brand {
    height: 72px;
    color: #fff;
    background-color: #c73442;
    position: sticky;
    top: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 72px;
    text-align: center;
    text-transform: uppercase;
}

.brand.test {
    background-color: #f6ab2f;
    color: #000;
}

ul {
    list-style: none
}

.nav-wrap {
    display: flex;
    flex-flow: column nowrap;
    height: calc(100vh - 72px);
    position: sticky;
    top: 72px;
}

.primary-menu ul,
.support-menu ul {
    width: 100%;
}

.primary-menu ul>li,
.support-menu ul>li {
    align-items: center;
    color: #fff;
    font-weight: 500;
    display: flex;
    font-size: 14px;
    width: auto;
    height: 56px;
    line-height: 1;
    padding: 0 30px;
    cursor: pointer;
}

.primary-menu ul>li.active {
    background: linear-gradient(45deg, rgba(15, 91, 194, .9), rgba(58, 177, 230, .9));
}

.support-menu {
    margin-top: auto;
}

.logout {
    border-top: 1px solid hsla(0, 0%, 100%, .2);
}

.logout li {
    font-size: 14px;
    align-items: center;
    display: flex;
    padding: 0;
    height: 72px;
}

.logout div {
    width: 100%;
}

.support-menu a {
    color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.logout button {
    color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    border: none;
    padding: 0;
    text-align: left;
    display: block;
    width: 100%;
    height: 56px;
}
</style>