import { ref, watch } from "vue";

const currentPage = ref(new URLSearchParams(window.location.search).get('page') ?? '');

let skipWatch = ref(false);

watch(() => currentPage.value, (currentPage) => {
    if (!skipWatch.value) {
        const searchURL = new URL(window.location);
        searchURL.searchParams.set('page', currentPage);

        let updateHistory = false;
        if (currentPage && currentPage.length) {
            searchURL.searchParams.set('page', currentPage)
            updateHistory = true;
        } else if (!searchURL.searchParams.get('page')) {
            searchURL.searchParams.delete('page');
            updateHistory = true;
        }
        if (updateHistory) {
            window.history.pushState({}, '', searchURL)
        }
    }
    skipWatch.value = false;

})

window.onpopstate = function () {
    skipWatch.value = true;
    const searchURL = new URL(window.location);
    const page = searchURL.searchParams.get('page');

    if (!page) {
        currentPage.value = '';
    } else {
        currentPage.value = page;
    }
    
};

export { currentPage }