<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import OfFormatAmount from 'of_vue_utils/src/utils/OfFormatAmount.vue';
import OfFormatDateTime from 'of_vue_utils/src/utils/OfFormatDateTime.vue';
import OfButton from 'of_vue_utils/src/utils/OfButton.vue';
import requests from '../../OfEventRequests';
import OfSmsWinners from './OfSmsWinners';

const props = defineProps({
    campaign: Object,
})

const emit = defineEmits(['reloadCampaigns']);

const keywordsString = computed(() => {
    if (props.campaign.keywords.length === 1) {
        return '"' + props.campaign.keywords[0].toUpperCase() + '"';
    } else {
        return '"' + props.campaign.keywords.join('", "') + '"';
    }
});

function stopCampaign() {
    if (confirm("Du er ved at stoppe denne lodtrækning. Er du sikker?")) {
        requests.post.stopSmsCampaign(props.campaign.campaign.campaignGuid).then(() => {
            emit('reloadCampaigns');
        })
    }
}
function startCampaign() {
    requests.post.startSmsCampaign(props.campaign.campaign.campaignGuid).then(() => {
        emit('reloadCampaigns');
    })
}
function drawWinner() {
    requests.post.drawSmsCampaignWinner(props.campaign.campaign.campaignGuid).then((result) => {
        if (result) {
            emit('reloadCampaigns');
        }
    })
}
</script>

<template>
    <div v-if="campaign.campaign.serviceType === 'SINGLE'" class="campaign-header">
        <span v-if="campaign.campaign.state === 'Active'">Aktiv donation</span>
        <span v-else-if="campaign.campaign.state === 'Stopped'">Stoppet donation</span>
        <span v-else>{{ campaign.campaign.state }}</span>
    </div>

    <div v-else-if="campaign.campaign.serviceType === 'LOTTERY'" class="campaign-header">

        <span v-if="campaign.campaign.state === 'Active'">Aktiv lodtrækning</span>
        <span v-else-if="campaign.campaign.state === 'Stopped'">Stoppet lodtrækning</span>
        <span v-else>{{ campaign.campaign.state }}</span>

        <OfButton v-if="campaign.campaign.state === 'Stopped'" text="Start igen" @click="startCampaign()" />

        <OfButton v-if="campaign.campaign.state === 'Stopped' && campaign.campaign.winnerCount > 1" text="Træk vindere"
            @click="drawWinner()" />
        <OfButton v-else-if="campaign.campaign.state === 'Stopped'" text="Træk vinder" @click="drawWinner()" />
        <OfButton v-else-if="campaign.campaign.state === 'Active'" text="Stop" @click="stopCampaign()" />
    </div>

    <div class="campaign-body">
        <!-- Name -->
        <h3 v-if="campaign.campaign.campaignName">
            {{ campaign.campaign.campaignName }}
        </h3>
        <h3 v-else>Unavngiven</h3>

        <!-- Details -->
        <div class="campaign-detail">
            <div>
                <span>Keywords</span>
                <span class="text-align-right">{{ keywordsString }}</span>
            </div>
            <div>
                <span>Pris pr. SMS</span>
                <span class="text-align-right">
                    <OfFormatAmount :amount="campaign.campaign.amount" :currency="campaign.campaign.currencyCode" />
                </span>
            </div>
            <div v-if="campaign.campaign.serviceType === 'LOTTERY'">
                <span>Antal vindere</span>
                <span class="text-align-right">
                    {{ campaign.campaign.winnerCount }}
                </span>
            </div>
            <div v-if="campaign.campaign.serviceType === 'LOTTERY' && campaign.campaign.maxParticipations > 0">
                <span>Max deltagelse</span>
                <span class="text-align-right">
                    {{ campaign.campaign.maxParticipations }}
                </span>
            </div>
        </div>

        <!-- Timestamps -->
        <div v-if="campaign.campaign.startTs || campaign.campaign.endTs" class="campaign-detail">
            <div v-if="campaign.campaign.startTs">
                <span>Start</span>
                <OfFormatDateTime :date-time="campaign.campaign.startTs" />

            </div>
            <div v-if="campaign.campaign.stoppedTs">
                <span>Stoppet</span>
                <OfFormatDateTime :date-time="campaign.campaign.stoppedTs" />
            </div>
            <div v-else-if="campaign.campaign.endTs">
                <span>Slut</span>
                <OfFormatDateTime :date-time="campaign.campaign.endTs" />
            </div>
        </div>

        <div class="campaign-detail">
            <div>
                <span>Indsamlet beløb</span>
                <span class="text-align-right">
                    <OfFormatAmount :amount="campaign.totalAmount" :currency="campaign.campaign.currencyCode" />
                </span>
            </div>
            <div>
                <span>Antal SMS'er</span>
                <span class="text-align-right">{{ campaign.ticketCount }} stk.</span>
            </div>
        </div>

        <OfSmsWinners v-if="campaign.campaign.serviceType === 'LOTTERY' && campaign.campaign.state === 'Stopped'"
            :campaign="campaign" />

    </div>
</template>

<style scoped>
.campaign.green .campaign-header {
    color: #38b249;
    border-color: #38b249;
    background-color: rgba(56, 178, 73, .1);
}

.campaign.blue .campaign-header {
    color: #1665d8;
    border-color: #1665d8;
    background-color: rgba(22, 101, 216, .1);
}

.campaign-header {
    height: 60px;
    padding: 15px 15px 0;
    border: 1px solid #212121;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.campaign-header :deep(button) {
    float: right;
    margin-left: 15px;
    min-height: 30px;
    height: 30px;
    padding: 0 12px;
}

h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin: 0;
}

.campaign-header span {
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
}

.campaign-body {
    padding: 15px;
    border: 1px solid #212121;
    border-top: 0;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.campaign-detail {
    padding-bottom: 10px;
    border-bottom: 1px solid #b7b7b7;
    margin-bottom: 10px;
}

.campaign-detail:last-of-type {
    padding-bottom: 0;
    border: 0;
    margin-bottom: 0;
}

.campaign-detail>div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
}

.text-align-right {
    text-align: right !important;
}
</style>