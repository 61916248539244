import { ref } from "vue";

const configProduction = false;

const apiUrl = (configProduction ? 'https://event.api.v1.onlinefundraising.dk' : 'https://development.event.api.onlinefundraising.dk');
const callcenterUrl = (configProduction ? 'https://knaekcancer.callcenter.onlinefundraising.dk' : 'https://testevent.callcenter.onlinefundraising.dk'); // https://callcenter.event.onlinefundraising.dk

const eventId = ref(localStorage.getItem('eventId'));

const validUntilTs = ref(localStorage.getItem('validUntilTs'));

const event = ref(localStorage.getItem('eventId'));

const searchOptions = ref({
    searchField:
        [
            { slug: 'COMPANY_NAME', name: 'Søg på firmanavn' },
            { slug: 'DISPLAY_NAME', name: 'Søg på visningsnavn' },
            { slug: 'BUSINESS_CODE', name: 'Søg på CVR-nr.' },
            { slug: 'MSISDN', name: 'Søg på tlf.nr.' },
            { slug: 'PERSONAL_NAME', name: 'Søg på kontaktpersons navn' },
            { slug: 'EMAIL', name: 'Søg på email' }
        ]
})

const user = ref({
    authToken: null,
    userGuid: null,
    name: null
});

export { apiUrl, callcenterUrl, eventId, validUntilTs, event, user, searchOptions, configProduction }