<script setup>
import requests from '../../OfEventRequests';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import OfCampaign from '../entities/OfCampaign.vue';
import OfCheckbox from 'of_vue_utils/src/utils/OfCheckbox.vue';

const campaigns = ref([]);
const rapidRefresh = ref(false);

let interval;

const refreshInterval = computed(() => {
    if (rapidRefresh.value) {
        return 5000;
    } else {
        return 60000
    }
});
const sortedCampaigns = computed(() => {
    const sorted = campaigns.value;
    if (sorted) {
        sorted.sort(function (a) {
            return a.campaign.serviceType == 'LOTTERY' ? -1 : 1;
        });
    }
    return sorted;
})

function getCampaigns() {
    requests.get.smsCampaigns().then((results) => {
        campaigns.value = results;
    })
}
function setCampaignRefreshInterval() {
    clearInterval(interval)
    interval = setInterval(() => {
        getCampaigns();
    }, refreshInterval.value);
}

onMounted(() => {
    getCampaigns();
    setCampaignRefreshInterval();
})
onUnmounted(() => {
    clearInterval(interval)
})
</script>

<template>
    <div class="heading-wrapper">
        <h2 class="title">SMS</h2>
        <OfCheckbox v-model="rapidRefresh" :checked="rapidRefresh" @change="setCampaignRefreshInterval">
            Opdatér hyppigt
        </OfCheckbox>
    </div>

    <div class="campaigns">
        <div v-for="campaign in sortedCampaigns" :key="campaign" class="campaign" :class="{
            green: campaign.campaign.state === 'Active',
            blue: campaign.campaign.state === 'Stopped',
        }">
            <OfCampaign :campaign="campaign" @reload-campaigns="getCampaigns" />
        </div>
    </div>
</template>

<style scoped>
.heading-wrapper h2 {
    width: 175px;
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
    margin: 0 0 1em;
    font-size: 1.1rem;
    font-weight: 500;
}

.campaigns {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.campaign {
    margin-bottom: 30px;
}
</style>
