<script setup>
import { onMounted, ref, defineProps, onUnmounted } from 'vue';
import requests from '../../OfEventRequests';
import OfFormatMsisdn from 'of_vue_utils/src/utils/OfFormatMsisdn.vue';
import OfFormatDateTime from 'of_vue_utils/src/utils/OfFormatDateTime.vue';

const props = defineProps({
    campaign: Object,
})

const winners = ref([]);
const revealedPhoneNumber = ref(false);

let interval;

function getWinners() {
    requests.get.smsCampaignWinners(props.campaign.campaign.campaignGuid).then((result) => {
        winners.value = result;

        if (winners.value && props.campaign.campaign.winnerCount >= winners.value.length) {
            clearInterval(interval);
        }
    })
}
function getWinnersContinuously() {

    clearInterval(interval);
    interval = setInterval(() => {
        getWinners();
    }, 5000)
}

onMounted(() => {
    getWinners();
    getWinnersContinuously();
})
onUnmounted(() => {
    clearInterval(interval);
})
</script>

<template>
    <div class="winners">
        <h4 v-if="props.campaign.campaign.winnerCount > 1">Vindere</h4>
        <h4 v-else>Vinder</h4>

        <template v-if="winners.length">
            <div v-for="winner in winners" :key="winner.messageGuid" class="winner-row">
                <div class="col-left">
                    <button v-if="winner.state == 'Pending'" type="button" class="pending-msisdn"
                        @click="revealedPhoneNumber = !revealedPhoneNumber">
                        <p>Vinder fundet. Kontrollerer betaling.</p>
                        <OfFormatMsisdn v-if="revealedPhoneNumber" :msisdn="winner.msisdn" />
                    </button>

                    <button v-else-if="winner.state == 'Invalid'" type="button" class="pending-msisdn"
                        @click="revealedPhoneNumber = !revealedPhoneNumber">
                        <p>Vinder afvist grundet manglende betaling.</p>
                        <OfFormatMsisdn v-if="revealedPhoneNumber" :msisdn="winner.msisdn" />
                    </button>

                    <span v-else-if="winner.state == 'Charged'" class="winner-msisdn">
                        <OfFormatMsisdn :msisdn="winner.msisdn" />
                    </span>
                </div>
                <div class="col-right">
                    <span style="cursor: text;" title="Tidspunkt for opkrævning">
                        <OfFormatDateTime style="pointer-events: none;" :date-time="winner.chargedTs" />
                        <p :class="{
                            green: winner.charged === true,
                            red: winner.charged === false && winner.state === 'Invalid',
                            blue: winner.charged === false && winner.state === 'Pending',
                        }">
                            {{ winner.state }}
                        </p>
                    </span>
                </div>
            </div>
        </template>

        <p v-else>Afventer ...</p>
    </div>
</template>

<style scoped>
h4 {
    font-size: .9rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin: 0;
}

.winner-row {
    display: block;
    border-top: 1px solid #b7b7b7;
    padding: 6px 0;
    min-height: 40px;
}

.col-left {
    display: inline-block;
    width: 75%;
}

.col-right {
    display: inline-block;
    width: 25%;
    text-align: right;
}

.winners>div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
}

.invalid-msisdn,
.pending-msisdn {
    border: 0;
    background: none;
    line-height: 1em;
    padding: 0;
    margin: 0;
    text-align: left;
    cursor: pointer;
}

.winner-msisdn {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
}

.green {
    color: #38b249;
}

.red {
    color: #E6492D;
}

.yellow {
    color: #F6AB2F;
}
</style>