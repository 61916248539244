<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import OfTable from 'of_vue_utils/src/utils/OfTable.vue';
import OfEntityListHeader from 'of_vue_utils/src/utils/OfEntityListHeader.vue';
import OfIcon from 'of_vue_utils/src/utils/OfIcon.vue';
import requests from '../../OfEventRequests';
import { searchOptions } from '../../config.js'
import { phraseState, getStateColor } from '../../functions.js';

const props = defineProps({
    target: Object
})

const emit = defineEmits(['merge', 'close']);

const filters = ref({
    searchField: 'COMPANY_NAME',
    Search: '',
})

const mergeSources = ref([]);

function updateFilters() {
    if (filters.value.Search.length) {
        searchBusinessPayments();
    } else {
        mergeSources.value = []
    }
}
function searchBusinessPayments() {
    const query = `?searchField=${filters.value.searchField}&searchValue=${filters.value.Search}&pageNumber=1&pageSize=50`;
    requests.get.businessPaymentRequests(query).then((result) => {

        mergeSources.value = result.businessPayments.filter(payment => payment.businessPaymentGuid != props.target.businessPaymentGuid && payment.state != 'MERGED');

    })
}
function selectMergeSource(businessPayment) {
    if (confirm('Flet den valgte donation med den åbne?')) {
        requests.post.claimBusinessPayment(businessPayment.businessPaymentGuid).then(result => {
            if (result) {
                const claim = { claimId: result.claimId }

                emit('merge', businessPayment, claim)
                setTimeout(() => {
                    searchBusinessPayments();

                }, 200);
            }
        })
    }
}
</script>

<template>
    <div>
        <OfEntityListHeader title="Kilde" :default-filters="filters" entity="businessPayment" placeholder="Indsæt søgeord"
            @updateFilters="updateFilters" :selectOptions="searchOptions">
            <OfIcon type="close" class="close-merge" @click="emit('close')" />
        </OfEntityListHeader>
        <p>Find en donation som skal flettes ind i den du har åben. Den flettede donation vil ikke blive vist på TV.
            Den åbne donation vil repræsentere summen af begge donationer, og skal godkendes påny inden visning på TV.</p>
    </div>

    <div class="merge-sources">
        <OfTable type="entity">
            <template #thead>
                <th>Status</th>
                <th>CVR-nr.</th>
                <th>Beløb</th>
                <th>Visningsnavn</th>
            </template>

            <template v-if="mergeSources" #tbody>
                <tr v-for="payment in mergeSources.slice(0, 10)" @keyup="keyup" v-bind:key="payment.businessPaymentGuid"
                    tabindex="0" :class="getStateColor(payment, 'name')" @click="selectMergeSource(payment)">
                    <td>
                        {{ phraseState(payment) }}
                    </td>
                    <td>
                        {{ payment.businessCode }}
                    </td>
                    <td>
                        {{ payment.amount }}
                    </td>
                    <td>
                        {{ payment.displayName }}
                    </td>
                </tr>

                <tr v-if="!filters.Search || !filters.Search.length">
                    <td colspan="4">Klar til søgning</td>
                </tr>

                <tr v-else-if="!mergeSources.length">
                    <td colspan="4">Ingen resultater fundet</td>
                </tr>

            </template>
        </OfTable>
    </div>
</template>

<style scoped>
.close-merge {
    cursor: pointer;
}

div {
    padding: 1rem;
}

.merge-sources :deep(.of-entity-list tr) {
    background-color: revert !important;
}
</style>